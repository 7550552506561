import React from 'react';
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useStore} from "../../index";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '60px 40px 0 40px',
        [theme.breakpoints.down('sm')]: {
          padding: '40px 20px 0 20px'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '120px 100px 0 100px'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '150px 150px 0 150px'
        }
    },
    solutionProposals: {
        display: 'flex',
        gap: '40px',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: '0',
        }
    },
    solutionBlock: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        marginBottom: '40px',

        [theme.breakpoints.down('sm')]: {
            marginBottom: '20px',
        }
    },
    sectionTitle: {
        fontFamily: 'Montserrat-Bold',
        textAlign: 'left',
        color: '#232323',
        fontSize: '40px',
        lineHeight: '49px',
        marginBottom: '32px',
        [theme.breakpoints.down("sm")]: {
            fontSize: '20px',
            marginBottom: '20px',
        }

    },
    boxView: {
        width: '50%',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    subTitleText: {
        fontFamily: 'Montserrat',
        color: '#232323',
        fontSize: '20px',
        lineHeight: '24px',
        marginBottom: '16px',
        letterSpacing: '3%',
        [theme.breakpoints.down("sm")]: {
            fontSize: '14px',
            marginBottom: '10px',
        }
    },

    img: {
        [theme.breakpoints.down('sm')]: {
            width: '20%',
        }
    }
}));


const Solution = () => {
    const classes = useStyles();

    const {content} = useStore()


    return (
        <Box id="solution" className={classes.root}>
            <Box className={classes.boxView}>

                <Typography className={classes.sectionTitle}>
                    {content['solution-section'][0].title}
                </Typography>
                {
                    content['solution-section'][0].subtitle.map(({subitem},index)=>(
                        <Typography key={index} className={classes.subTitleText}  dangerouslySetInnerHTML={{__html: subitem}} >
                        </Typography>
                    ))
                }

            </Box>
            <Box className={classes.solutionProposals}>
                <Box>
                    {content['solution-section'][0]['solution-items'][0]['solution-left'].map((item) => (
                        <Box key={item.id} className={classes.solutionBlock}>
                            <img src={item.img} alt="solution" className={classes.img}/>
                            <Typography className={classes.subTitleText}>
                                {item.title}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box>
                    {content['solution-section'][0]['solution-items'][0]['solution-right'].map((item) => (
                        <Box key={item.id} className={classes.solutionBlock}>
                            <img src={item.img} alt="solution" className={classes.img}/>
                            <Typography className={classes.subTitleText}>
                                {item.title}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default Solution;

