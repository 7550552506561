import React from "react";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DownloadButton from "../UI/DownloadButton";
import {useStore} from "../../index";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 20px'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0 100px'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '0 150px 0 150px'
        }
    },
    mainSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: '1px solid #232323',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    sectionLeft: {
        width: '50%',
        borderRight: '1px solid #232323',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            borderRight: 'none',
        }
    },
    section: {
        width: '50%',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    titleSection: {
        fontFamily: 'Montserrat-Bold',
        fontWeight: '700',
        lineHeight: '24px',
        fontSize: '20px',
        color: '#232323',
        marginBottom: '14px',
        padding: '10px 10px 0 10px',

        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        }
    },
    subTitleSection: {
        fontFamily: 'Montserrat',
        fontSize: '20px',
        color: '#232323',
        fontWeight: '400',
        //lineHeight: '24px',
        padding: '0 10px 14px 10px',

        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        }
    },
    map: {
        width: '100%',
        height: '100%',
        marginBottom: '20px',
    },
    img: {
        width: '100%',
        padding: '0 10px 10px 10px',
    },
    aboutMoreSection: {
        border: '1px solid #232323',
        borderTop: 'none',
    },
    buttonBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '0 10px 10px 10px',
        alignItems: 'center',
    },
    description: {
        fontFamily: 'Montserrat',
        fontSize: '20px',
        color: '#232323',
        fontWeight: '400',

        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        }
    }
}));

const TableSolution = () => {
    const classes = useStyles();
    const {content} = useStore();

    return (
        <Box id="reason" className={classes.root}>
            <Box className={classes.mainSection}>
                <Box className={classes.sectionLeft}>
                    <Typography className={classes.titleSection}>
                        {content['reason-section'][0]['blockLeft'][0].title}

                    </Typography>
                    <Typography className={classes.subTitleSection}>
                        {content['reason-section'][0]['blockLeft'][0].subtext}
                    </Typography>
                    <img src={content['reason-section'][0]['blockLeft'][0].img} alt="map" className={classes.img}/>
                </Box>
                <Box className={classes.section}>
                    <Box>
                        <Typography className={classes.titleSection}>
                            {content['reason-section'][0]['blockright'][0]['blockItem'][0].title}
                        </Typography>
                        <Typography className={classes.subTitleSection}
                                    dangerouslySetInnerHTML={{__html: content['reason-section'][0]['blockright'][0]['blockItem'][0].subtext[0].text}}

                                    style={{borderBottom: '1px solid #232323'}}>

                        </Typography>
                    </Box>
                    <Box>
                        <Typography className={classes.titleSection}>
                            {content['reason-section'][0]['blockright'][0]['blockItem'][1].title}
                        </Typography>
                        {
                             content['reason-section'][0]['blockright'][0]['blockItem'][1]['subtext'].map((item)=>(

                                 <Typography className={classes.subTitleSection}
                                             dangerouslySetInnerHTML={{__html:item.text}}
                                 >

                                 </Typography>
                             ))
                        }

                    </Box>
                </Box>
            </Box>
            <Box className={classes.aboutMoreSection}>
                <Typography className={classes.titleSection} align="center">
                    {content['reason-section'][0]['blockMore'][0].title}
                </Typography>
                <Typography className={classes.subTitleSection} style={{textAlign: 'left'}}
                    dangerouslySetInnerHTML={{__html: content['reason-section'][0]['blockMore'][0].subtext}}
                >

                </Typography>
                <Box className={classes.buttonBlock}>
                    <Typography className={classes.description} style={{textAlign: 'left'}}>
                        {content['reason-section'][0]['blockMore'][0]['btnDownload']}
                    </Typography>
                    <DownloadButton/>
                </Box>
            </Box>
        </Box>
    );
};

export default TableSolution;

