import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {IconButton, Drawer, Box, Link} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import './styles.css';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Logo from "../../Image/Logo";
import MobileIcon from "../../Image/Contact/mobile-icon.svg";
import MailIcon from "../../Image/Contact/mail-icon.svg";
import {useStore} from "../../../index";

const navLinksData = [
    {id: 'problem', text: 'Problem'},
    {id: 'solution', text: 'Rozwiązanie'},
    {id: 'authors', text: 'Nasz zespół'},
];

const useStyles = makeStyles((theme) => ({
    menuButton: {
        color: '#232323',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    list: {
        width: '100%',
        height: '100%',
        padding: '10px 20px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

    },
    drawer: {
        width: '100%',
        height: '100%',
        backgroundColor: '#b7d8e5',
    },
    navBlock: {
        color: '#fcfbff',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',

    },
    navigationText: {
        fontFamily: 'Montserrat',
        fontSize: '16px',
        fontWeight: 400,
        color: '#232323',
        textTransform: 'none',
        '&:hover': {
            color: '#003E56',
        },
    },
    closeIcon: {
        color: '#fcfbff',
        fontSize: '30px',
        marginLeft: 'auto',
        '&:hover': {
            color: '#003E56',
        },
    },
    headerBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    root: {
        width: '100%',
        backgroundColor: '#b7d8e5',
    },
    title: {
        fontFamily: 'Montserrat-Bold',
        fontWeight: '700',
        lineHeight: '24px',
        fontSize: '20px',
        color: '#232323',
        marginBottom: '20px',
    },
    text: {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        color: '#232323',
        fontWeight: '400',
        lineHeight: '17px',
    },
    contactSection: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        marginBottom: '16px',
        textDecoration: 'none',
        color: '#232323',
    }
}));

function ToggleMenu() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const {content} = useStore();
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        window.scrollTo({
            top: section.offsetTop,
            behavior: 'smooth',
        });
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(open);
    };

    const list = () => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <Box className={classes.headerBlock}>
                    <Logo />
                <CloseIcon className={classes.closeIcon} onClick={toggleDrawer(false)}/>
            </Box>
            <Box className={classes.navBlock}>
                {content['navigation'].filter((link)=>link.id !== "contact").map((link) => (
                    <Button onClick={() => scrollToSection(link.id)} style={{padding: '0 0 16px 0'}}>
                        <Typography className={classes.navigationText}>
                            {link['link-text']}
                        </Typography>
                    </Button>
                ))}

            </Box>
            <Box className={classes.root}>
                <Typography className={classes.title}>
                    {content['contact'][0]['title']}
                </Typography>
                <Typography className={classes.text} style={{marginBottom: '16px'}}
                            dangerouslySetInnerHTML={{__html: content['contact'][0]['susubtitle']}}     >

                </Typography>
                <Box>
                    <Box>
                        <Link href="tel:504297093">
                            <Box className={classes.contactSection}>
                                <img src={MobileIcon} alt="mobile-icon"/>
                                <Typography>
                                    {content['contact'][0]['mobile']}
                                </Typography>
                            </Box>
                        </Link>
                    </Box>

                    <Box>
                        <Link href={`mailto:${content['contact'][0]['mail']}`}>
                            <Box className={classes.contactSection}>
                                <img src={MailIcon} alt="mail-icon"/>
                                <Typography>
                                    {content['contact'][0]['mail']}
                                </Typography>
                            </Box>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </div>
    );

    return (
        <div>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
                        onClick={toggleDrawer(true)}>
                <MenuIcon/>
            </IconButton>
            <Drawer anchor="bottom" open={open} onClose={toggleDrawer(false)} classes={{paper: classes.drawer}}>
                {list()}
            </Drawer>
        </div>
    );
}

export default ToggleMenu;
