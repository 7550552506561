import React from "react";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useStore} from "../../index";


const useStyles = makeStyles((theme) => ({
    aboutSection: {
        backgroundColor: 'white',
        height: '100%',
        padding: '100px 40px 0 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '60px 20px 0 20px'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '100px 100px 0 100px'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '150px 150px 0 150px'
        }
    },
    container: {
        paddingBottom: '22px',
    },
    sectionTitle: {
        fontFamily: 'Montserrat-Bold',
        textAlign: 'left',
        color: '#232323',
        fontSize: '40px',
        lineHeight: '49px',
        marginBottom: '44px',
        [theme.breakpoints.down("sm")]: {
            fontSize: '20px',
            marginBottom: '20px',
        }

    },
    boxView: {
        display: 'flex',
        width: '65%',
        alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
        }
    },
    subTitleSection: {
        height: '60px',
        border: '1px solid #232323',
        borderBottom: 'none',
    },
    subTitle: {
        fontFamily: 'Montserrat-Bold',
        color: '#232323',
        backgroundColor: 'white',
        position: 'relative',
        top: '-22px',
        padding: '10px',
        fontSize: '20px',
        lineHeight: '24px',

        [theme.breakpoints.down("sm")]: {
            fontSize: '12px',
        }
    },
    img: {
        width: '50%',

        [theme.breakpoints.down("sm")]: {
            width: '100%',
        }
    },
    problemContainer: {
        position: 'relative',
        top: '-35px',
        width: '100%',
        gap: '20px',
        display: 'flex',
        flexDirection: 'row',
        padding: '0 12px',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column',
        }
    },
    problemDescription: {
        fontFamily: 'Montserrat',
        fontSize: '20px',
        lineHeight: '24px',
        color: '#232323',
        fontWeight: '400',
        letterSpacing: '2.5%',
        [theme.breakpoints.down("sm")]: {
            fontSize: '14px',
        }
    }
}));

function Problem() {
    const classes = useStyles();
    const {content} = useStore();
    console.log(content)

    return (
        <Box className={classes.aboutSection} id="problem">
            <Box className={classes.boxView}>
                <Typography className={classes.sectionTitle}>
                    {content['problemSection'][0].title}
                </Typography>
            </Box>
            <Box>
                <Box className={classes.container}>
                    <Box>
                        <Box className={classes.subTitleSection}>
                            <Box>
                                <Typography className={classes.subTitle} style={{float: 'right'}}>
                                    {content['problemSection'][0]['problemitem'][0].title}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.problemContainer}>
                            <Typography className={classes.problemDescription}>
                                {content['problemSection'][0]['problemitem'][0].description}
                            </Typography>
                            <img src={content['problemSection'][0]['problemitem'][0].img} alt="mapa" className={classes.img}/>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.container}>
                    <Box>
                        <Box className={classes.subTitleSection}>
                            <Box>
                                <Typography className={classes.subTitle} style={{float: 'left'}}>
                                    {content['problemSection'][0]['problemitem'][1].title}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.problemContainer}>
                            <img src={content['problemSection'][0]['problemitem'][1].img} alt="mapa" className={classes.img}/>
                            <Typography className={classes.problemDescription}>
                                {content['problemSection'][0]['problemitem'][1].description}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.container}>
                    <Box>
                        <Box className={classes.problemContainer}>
                            <Typography className={classes.problemDescription}>
                                {content['problemSection'][0]['problemitem'][2].description}
                            </Typography>
                            <img src={content['problemSection'][0]['problemitem'][2].img} alt="mapa" className={classes.img}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default Problem;

