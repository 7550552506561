import React, {useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Logo from "../../Image/Logo";
import {Box} from "@material-ui/core";
import ToggleMenu from "../ToggleMenu";
import {useStore} from "../../../index";


const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: 'white',
        boxShadow: 'none',
        transition: "all 0.3s ease-out",
    },
    hidden: {
        transform: "translate(0, -100%)",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    toolbar: {
        padding: '0 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 20px',
            display: 'flex',
            justifyContent: 'space-between',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '10px 100px'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '20px 150px 0 150px'
        }
    },
    title: {
        flexGrow: 1,
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    navButton: {
        color: '#232323',
        fontSize: '20px',
        fontFamily: 'Montserrat',
        textTransform: 'none',
        fontWeight: 400,
        lineHeight: '24px',
        fontHeight: '20px',
        padding: '16px',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#003E56',
        }
    },
}));

function Header() {
    const classes = useStyles();
    //TODO: refactor
    const [scrolled, setScrolled] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

    const {content} = useStore();
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        window.scrollTo({
            top: section.offsetTop,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [prevScrollPos, visible]);


    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <AppBar position="fixed" className={`${classes.appBar} ${!visible && classes.hidden}`}
        >

            <Toolbar className={classes.toolbar}>
                <Box>
                    <Typography>
                        <Button onClick={() => scrollToSection('banner')}>
                            <Logo/>
                        </Button>
                    </Typography>
                </Box>
                <Box className={classes.title}>
                    {content['navigation'].map((link) => (
                        <Button
                            key={link.id}
                            className={classes.navButton}
                            onClick={() => scrollToSection(link.id)}
                        >
                            {link['link-text']}
                        </Button>
                    ))}
                </Box>
                <Box>
                    <ToggleMenu/>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Header;

