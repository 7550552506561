import React from 'react';
import { Button } from '@material-ui/core';
import { saveAs } from 'file-saver';
import {makeStyles} from "@material-ui/core/styles";
import file from './Opracowanie.pdf'
import {useStore} from "../../../index";

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: '#36a758',
        padding: '10px 0',
        color: '#fff',
        fontFamily: 'Montserrat',
        fontSize: '20px',
        lineHeight: '24px',
        textTransform: 'none',
        borderRadius: '0',
        width: '50%',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#36a758',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '10px 8px',
            fontSize: '14px',
        }
    }
}));


const DownloadButton = () => {
    const classes = useStyles();
    const {content} = useStore();

    const handleDownload = () => {
        saveAs(file, 'Opracowanie.pdf');
    };

    return (
        <Button className={classes.button} onClick={handleDownload}>
            {content['reason-section'][0]['blockMore'][0]['btntext']}
        </Button>
    );
};

export default DownloadButton;
