import React, {Component} from 'react';
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import {CssBaseline} from "@material-ui/core";
import Banner from "./components/Banner";
import Solution from "./components/Solution";
import TableSolution from "./components/TableSolution";
import Problem from "./components/Problem";
import BackgroundBannerBetweenSections from "./components/BackgroundSection";
import Authors from "./components/Authors";
import Wrapper from "./components/Layout/LoadContent/Wrapper";

class App extends Component {



    render() {
        return (
            <>
                <Wrapper>
                    <CssBaseline />
                    <Header />
                    <Banner />
                    <Problem />
                    <BackgroundBannerBetweenSections />
                    <Solution />
                    <TableSolution />
                    <Authors />
                    <Footer/>
                </Wrapper>
            </>
        );
    };
}

export default App;
