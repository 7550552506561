import React from "react";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import BannerIcon from "./first-banner.png";
import {useStore} from "../../index";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '130px 40px 0 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '130px 20px 0 20px'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '130px 100px 0 100px'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '150px 150px 0 150px'
        }
    },

    titleSection: {
        fontFamily: 'Montserrat-Bold',
        fontWeight: '700',
        lineHeight: '68px',
        color: '#003e56',
        fontSize: '56px',
        marginBottom: '20px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '32px',
        },
    },

    subTitleSection: {
        fontFamily: 'Montserrat',
        fontSize: '16px',
        color: '#232323',
        fontWeight: '400',
        lineHeight: '19.5px',

        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    banner: {
        width: '100%',
        height: 'auto',
        marginBottom: '20px',
    },
    textSection:  {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '20px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: '4px',
        }
    },
    accent: {
        fontFamily: 'Montserrat-Bold',
        fontWeight: '700',
        lineHeight: '19.5px',
        fontSize: '16px',
        display: 'contents',
    }

}));

function Banner() {
    const classes = useStyles();
    const {content} =useStore()
    return (
        <Box className={classes.root} id='banner'>
            <Typography className={classes.titleSection}>
                {content.banner[0].title}
            </Typography>
            <img src={content.banner[0].img} height={428} width={900} alt="John Doe" className={classes.banner}/>
            <Box className={classes.textSection}>
                <Typography className={classes.subTitleSection} dangerouslySetInnerHTML={{__html: content.banner[0]['text-box'][0]['information-text']}}>
                </Typography>
                <Typography className={classes.subTitleSection} dangerouslySetInnerHTML={{__html: content.banner[0]['text-box'][1]['information-text']}}>

                </Typography>
            </Box>
        </Box>
    );
}

export default Banner;






