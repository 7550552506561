import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Scrollbars} from 'react-custom-scrollbars';
import {useStore} from "../../../index";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(5px)',
    },
    paper: {
        width: '70%',
        height: '80%',
        position: 'relative',
        outline: 'none',
        borderRadius: '0',
        boxShadow: 'none',
        overflow: 'hidden',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
        }
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '30px 32px 0 32px',
        textAlign: 'left',
        height: '100%',
    },
    // modalButton: {
    //     backgroundColor: '#36a758',
    //     padding: '10px 100px',
    //     color: '#fff',
    //     fontFamily: 'Montserrat',
    //     fontSize: '20px',
    //     lineHeight: '24px',
    //     textTransform: 'none',
    //     borderRadius: '0',
    //     '&:hover': {
    //         backgroundColor: '#fff',
    //         color: '#36a758',
    //     },
    //     [theme.breakpoints.down('sm')]: {
    //         fontSize: '14px',
    //         padding: '10px 50px',
    //     }
    // },
    openButton: {
        backgroundColor: '#36a758',
        padding: '10px 8px',
        color: '#fff',
        fontFamily: 'Montserrat',
        fontSize: '20px',
        lineHeight: '24px',
        textTransform: 'none',
        borderRadius: '0',
        width: 'fit-content',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#36a758',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        }
    },
    modalCloseButton: {
        padding: '0',
    },
    modalTitleText: {
        fontFamily: 'Montserrat-Bold',
        fontSize: '24px',
        color: 'black',
        fontWeight: '700',
        lineHeight: '29px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
        }
    },
    scrollbar: {
        width: '100%',
        height: '70% !important',
        marginBottom: '60px !important',
        '& .track-vertical': {
            width: '2px !important',
            right: '2px !important',
            borderRadius: '0 !important',
        },
        '& .thumb-vertical': {
            width: '2px !important',
            borderRadius: '0 !important',
            backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
        },
    },
    authorsText: {
        fontFamily: 'Montserrat',
        fontSize: '16px',
        color: 'black',
        fontWeight: '400',
        lineHeight: '24px',

        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        }
    },
    participant: {
        marginBottom: '5px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '5px',
    },
}));




function AuthorsModal() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const {content}= useStore();

    const handleOpen = () => {

            document.body.parentElement.style.overflow='hidden'
            setOpen(true);

    }
    const handleClose = () => {
        document.body.parentElement.style.overflow=''
        setOpen(false);

    }



    const splitNameAndDescription = (participant) => {
        const parts = participant.split(' (');
        return {
            name: parts[0],
            description: parts[1] ? ` (${parts[1]}` : null,
        };
    };

    return (
        <>
            <Button className={classes.openButton} onClick={handleOpen}>
                Zobacz wszystkich
            </Button>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Paper className={classes.paper}>
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '40px 32px 0 32px',
                            }}
                        >
                            <Box className={classes.modalTitle}>
                                <Typography className={classes.modalTitleText}>
                                    {content.authors[0].title}
                                </Typography>
                            </Box>
                            <IconButton
                                className={classes.modalCloseButton}
                                aria-label="close"
                                onClick={handleClose}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                        <Box className={classes.modalContent}>
                            <Scrollbars
                                autoHide
                                autoHideTimeout={5000}
                                autoHideDuration={200}
                                className={classes.scrollbar}
                            >
                                <Typography>
                                    {content.popupautors.map(({autor}, index) => {
                                        const {name, description} = splitNameAndDescription(autor);
                                        return (
                                            <Box key={index} className={classes.participant}>
                                                <Typography
                                                    className={classes.authorsText}
                                                    style={{fontWeight: 'bold'}} // Apply bold style to names
                                                >
                                                    {name}
                                                </Typography>
                                                {description && (
                                                    <Typography
                                                        className={classes.authorsText}>{description}</Typography>
                                                )}
                                            </Box>
                                        );
                                    })}
                                </Typography>
                            </Scrollbars>
                            {/*<Box>*/}
                            {/*    <Button className={classes.modalButton} onClick={handleClose}>*/}
                            {/*        Widzę*/}
                            {/*    </Button>*/}
                            {/*</Box>*/}
                        </Box>
                    </Paper>
                </Fade>
            </Modal>
        </>
    );
}

export default AuthorsModal;
