import {useStore} from "../../../index";
import {useEffect} from "react";

export default function Wrapper({children}){

    const {content,loadContent} = useStore();

    useEffect(  ()=>{

        authenticate().then(() => {
            const ele = document.getElementById('ipl-progress-indicator')
            loadContent();
            if(ele){
                ele.classList.add('available')
                if(content){
                    setTimeout(() => {
                        ele.outerHTML = ''
                    }, 300)
                }

            }
        })

    },[])
   function authenticate () {
        return new Promise(resolve => setTimeout(resolve, 300))
    }





    return(<>

            { content && children}

        </>
    )
}

