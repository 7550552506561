import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@material-ui/core";
import App from './App';
import {create} from "zustand";

const theme = createTheme();

export const useStore = create((set)=>({
    content:null,
    loadContent: async ()=>{
        await fetch('https://wisladlakujaw.pl/backend/wp-json/acf/v3/pages/11/')
            .then((data)=>data.json())
            .then(data=>set({content:data['acf']}))
    }
}));




ReactDOM.render(

    <ThemeProvider theme={theme}>
        <Router>
            <App />
        </Router>
    </ThemeProvider>,
    document.getElementById('root')
);

