import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, A11y} from 'swiper';
import 'swiper/swiper.css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import React from 'react';

import {makeStyles} from "@material-ui/core/styles";
import {Box, Typography} from "@material-ui/core";
import AuthorsModal from "../UI/ModalAuthors";
import {useStore} from "../../index";



const useStyles = makeStyles((theme) => ({
    root: {
        padding: '100px 40px 0 40px',

        [theme.breakpoints.down('sm')]: {
            padding: '60px 20px 0 20px'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '100px 100px 0 100px'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '150px 150px 0 150px'
        }

    },
    slideCard: {
        backgroundColor: 'white',
        border: '1px solid #232323',
        height: 'auto',
    },
    img: {
        width: '100%',
        height: '200px',
        objectFit: 'cover',
        borderBottom: '1px solid #232323',
        [theme.breakpoints.down('sm')]: {
            height: '200px',
        },
        [theme.breakpoints.up('md')]: {
            height: '300px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '300px',
        },
        [theme.breakpoints.up('xl')]: {
            height: '400px',
        }
    },
    description: {
        padding: '10px',
        textAlign: 'center',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    authorName: {
        fontFamily: 'Montserrat-Bold',
        fontSize: '14px',
        lineHeight: '15px',
        color: '#232323',
        marginBottom: '5px',
    },
    authorPosition: {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        lineHeight: '17px',
        color: '#232323',
    },
    sectionTitle: {
        fontFamily: 'Montserrat-Bold',
        textAlign: 'left',
        color: '#232323',
        fontSize: '40px',
        lineHeight: '49px',
        marginBottom: '32px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            marginBottom: '20px',
            lineHeight: '24px',
        },
    },
    boxView: {
        width: '50%',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    subTitleText: {
        fontFamily: 'Montserrat',
        color: '#232323',
        fontSize: '20px',
        lineHeight: '24px',
        marginBottom: '32px',
        letterSpacing: '3%',

        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        }
    },
}));

const Authors = () => {
    const {content} = useStore();

    const classes = useStyles();
    return (
        <Box className={classes.root} id="authors">
            <Box className={classes.boxView}>
                <Typography className={classes.sectionTitle}>
                    {content.authors[0].title}
                </Typography>
            </Box>
            <Typography className={classes.subTitleText}>
                {content.authors[0].subtitle}

            </Typography>
            <Swiper
                modules={[Pagination, A11y]}
                spaceBetween={20}
                breakpoints={{
                    320: {
                        slidesPerView: 2,
                    },
                    600: {
                        slidesPerView: 3,
                    },
                    960: {
                        slidesPerView: 4,
                    },
                    1280: {
                        slidesPerView: 5,
                    },
                    1920: {
                        slidesPerView: 6,
                    }
                }}
                //rewind={true}
                pagination={{
                    dynamicBullets: true,
                    clickable: true,
                }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                autoplay={{delay: 3000}}
                style={{
                    paddingBottom: '60px',
                    "--swiper-pagination-color": "#b7d8e5",
                    "--swiper-pagination-bullet-inactive-color": "rgba(183, 216, 229, 0.3)",
                    "--swiper-pagination-bullet-inactive-opacity": "1",
                    "--swiper-pagination-bullet-size": "16px",
                    "--swiper-pagination-bullet-horizontal-gap": "6px",
                }}
            >
                {content.authors[0]['autor-slider'].map((item) => (
                    <SwiperSlide key={item.id} className={classes.slideCard}>
                        <img src={item.foto} alt={item.name} className={classes.img}/>
                        <Box className={classes.description}>
                            <Typography className={classes.authorName}>{item.name}</Typography>
                            <Typography className={classes.authorPosition}>{item.position}</Typography>
                        </Box>
                    </SwiperSlide>
                ))}
            </Swiper>
            <AuthorsModal/>
        </Box>
    );
};

export default Authors;
