import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from "@material-ui/core";
import Background from "../../components/Image/Backgrounds/background-between-section.png";
import {useStore} from "../../index";

const useStyles = makeStyles((theme) => ({
    background: {
        width: '100%',
    }
}));

const BackgroundBannerBetweenSections = () => {
    const classes = useStyles();
    const {content} =useStore()

    return (
        <Box>
            <img src={content['background-section']} alt="background"  className={classes.background}/>
        </Box>
    );
};

export default BackgroundBannerBetweenSections;
