import React from 'react';
import {Box, Typography, Link} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import MobileIcon from "../../../components/Image/Contact/mobile-icon.svg";
import MailIcon from "../../../components/Image/Contact/mail-icon.svg";
import {useStore} from "../../../index";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: '#b7d8e5',
        padding: '40px 40px',
        marginTop: '75px',

        [theme.breakpoints.down('sm')]: {
          padding: '60px 20px',
            marginTop: '50px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '100px'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '150px'
        }
    },
    title: {
        fontFamily: 'Montserrat-Bold',
        fontWeight: '700',
        lineHeight: '24px',
        fontSize: '20px',
        color: '#232323',
        marginBottom: '20px',
    },
    text: {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        color: '#232323',
        fontWeight: '400',
        lineHeight: '17px',
    },
    contactSection: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        marginBottom: '16px',
        textDecoration: 'none',
        color: '#232323',
    }
}));

const Footer = () => {
    const classes = useStyles();
    const {content} = useStore();

    return (
        <Box className={classes.root} id="contact">
            <Typography className={classes.title}>
                {content['contact'][0]['title']}
            </Typography>
            <Typography className={classes.text} style={{marginBottom: '16px'}}
                        dangerouslySetInnerHTML={{__html: content['contact'][0]['susubtitle']}}     >

            </Typography>
            <Box>
                <Box>
                    <Link href="tel:504297093">
                        <Box className={classes.contactSection}>
                            <img src={MobileIcon} alt="mobile-icon"/>
                            <Typography>
                                {content['contact'][0]['mobile']}
                            </Typography>
                        </Box>
                    </Link>
                </Box>
                <Box>
                    <Link href={`mailto:${content['contact'][0]['mail']}`}>
                        <Box className={classes.contactSection}>
                            <img src={MailIcon} alt="mail-icon"/>
                            <Typography>
                                {content['contact'][0]['mail']}
                            </Typography>
                        </Box>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

export default Footer;

